import { appKey, version, salt } from 'server/baseArgs'
import { ImenuList } from 'declare'
import Cookies from 'js-cookie'
import _ from 'lodash'
import md5 from 'js-md5'
import * as ExportJsonExcel from 'js-export-excel';

/**
 * 
 * @returns 获取当前时间戳
 */
export const getCurrentTimeStamp = (time?: string) => {
    let timeStamp = 0;
    if (time) {
        timeStamp = new Date(time).getTime()
    } else {
        new Date().getTime()
    }
    return timeStamp;
}
/**
 * 
 * @returns 拼接 sign
 */
export const getSign = (_str: string) => {
    let str = `appKey=${appKey}&bizContent=${_str}&extra=&salt=${salt}&timestamp=${getCurrentTimeStamp()}&version=${version}`
    let sign = md5(str).toUpperCase();
    return sign;
}
/**
 * 获取cookie 存取  删除
 */
export const getCookie = (name: string) => {
    return Cookies.get(name)
}
export const setCookie = (name: string, value: string | object) => {
    Cookies.set(name, value)
}
export const delCookie = (name: string) => {
    Cookies.remove(name);
}

/**
 *  判断对象的唯一值 判断是否在数组中
 */

export const isExistArray = (_obj: object, list: Array<any> = []) => {
    let flag: boolean = false;
    list.forEach(item => {
        if (_.isEqual(_obj, item)) {
            flag = true;
        }
    })
    return flag;
}

/**
 * 处理菜单数据
 */
export const dealMenuData = (menulist: any = [], parentId: number) => {
    let newArr: Array<ImenuList> = [];
    if (menulist.length) {
        menulist.map((item: ImenuList) => {
            if (Number(item.parentId) === Number(parentId)) {
                let obj: ImenuList = {
                    menuType: item.menuType,
                    icon: item.icon,
                    menuId: item.menuId,
                    menuName: item.menuName,
                    orderNum: item.orderNum,
                    parentId: item.parentId,
                    path: item.path,
                    createTime: item.createTime,
                    status: item.status,
                    visible: item.visible,
                    alias: item.alias,
                    // children: [],
                }
                let children: Array<ImenuList> = dealMenuData(menulist, item.menuId)
                if (children && children.length > 0) {
                    obj.children = children
                }
                newArr.push(obj)
            }
            return null
        })
    }
    return newArr
}
/**
     * 验证手机号是否为正确号码
     */
export const validatePhone = (phoneNum: string) => {
    let reg = /^(13[1-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return reg.test(phoneNum);
}
// 验证身份证号
export const validateCardNO = (cardNO: string) => {
    let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    return reg.test(cardNO);
}
/**
 * 导出json 为excel
 */

export const downloadExcel = (fileName: string, data: any[], argsNams: string[], headerName: string[]) => {
    let option: any = {};
    let dataTable: any[] = [], columnWidths: number[] = [];
    data.forEach((item, index) => {
        let obj: any = {};
        headerName.forEach((_item, _index) => {
            obj[_item] = item[argsNams[_index]]
            if (argsNams[_index] === 'number') {
                obj[_item] = index + 1
            }
            columnWidths.push(8)
        })
        dataTable.push(obj)
    })
    option.fileName = fileName
    option.datas = [
        {
            sheetData: dataTable,
            sheetName: 'sheet',
            // sheetFilter: headerName,
            sheetHeader: headerName,
            columnWidths: columnWidths,
        }
    ];
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
}

/**
 * 格式化时间日期
 * @param time 
 * @param format 
 * @returns 
 */
export const formattingDate = (time: string | any, format: any = 'yy-MM-dd') => {
    let t = new Date(time);
    let tf = function (i: number) { return (i < 10 ? '0' : '') + i };
    return format.replace(/yy|MM|dd|hh|mm|ss/g, function (a: string) {
        switch (a) {
            case 'yy':
                return tf(t.getFullYear());
            case 'MM':
                return tf(t.getMonth() + 1);
            case 'dd':
                return tf(t.getDate());
            case 'hh':
                return tf(t.getHours());
            case 'mm':
                return tf(t.getMinutes());
            case 'ss':
                return tf(t.getSeconds());
        }
    })
}
/**
 * 获取N天后的时间或者N个月后的时间
 */

export const getUnknownDayTime = (time: string, value: number, type: 'day' | 'month') => {
    let date = new Date(time);
    let year: number = 2000, month: string | number = 0, day: string | number = 1;
    let res = '';
    if (type === 'month') {
        date.setMonth(date.getMonth() + value);
        year = date.getFullYear();
        month = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        day = (date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate());
        res = `${year}-${month}-${day}`
    }
    if (type === 'day') {
        let timeStamp = date.getTime() + 60 * 60 * 1000 * 24 * value;
        res = new Date(timeStamp).toISOString().split('T')[0]
    }
    return res;
}
/**
 * 根据ID 匹配name
 */
export const getDataName = (list: any[], value: string | number, idArgs: string = 'value', nameArgs: string = 'label'): string => {
    let name = '-'
    list.forEach(item => {
        if (item[idArgs] === value) {
            name = item[nameArgs]
        };
    })
    return name;
}

/**
 * 深拷贝
 */
export const deepCopy = (obj: any) => {
    if (obj === null) return obj;
    if (obj instanceof Date) return new Date(obj);
    if (obj instanceof RegExp) return new RegExp(obj);
    if (typeof obj !== "object") return obj;
    let cloneObj = new obj.constructor();
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            // 实现一个递归拷贝
            cloneObj[key] = deepCopy(obj[key]);
        }
    }
    return cloneObj;
}

/**
 * 函数防抖
 */
export const debounce = (fn: any, debTime: number) => {
    let timer: any = null;
    return (...args: any[]) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, args);
            timer = null;
        }, debTime)
    }
}


export const backTbodyHeight = (h: number = 0) => {
    let customTable: any = document.getElementById('customTable');
    let clientHeight = document.body.clientHeight; // 浏览器可视区域高度
    let heigth = 0;

    if (customTable) {
        let tableTop = customTable.getBoundingClientRect().top; // 表格距离顶部高度
        console.log(customTable, 'customTable', clientHeight, 2, tableTop);
        if (tableTop) {
            heigth = clientHeight - tableTop - 40 - 56 - 60 - h; // 表头- 分页 - 底部footer
        } else {
            heigth = clientHeight - tableTop - 40 - 56 - 60 - h; // 表头- 分页 - 底部footer
        }
    }
    return heigth;

}
// 表格y轴高度
export const getHeight = (ref: any, h: number = 0) => {
    let customTable: any = ref.current;
    let clientHeight = document.body.clientHeight; // 浏览器可视区域高度
    let heigth = 300;
    if (customTable) {
        let tableTop = customTable.getBoundingClientRect().top; // 表格距离顶部高度
        heigth = clientHeight - tableTop - 40 - 56 - 60 - h; // 表头- 分页 - 底部footer
    }
    return heigth;
}

/**
  * 根据value返回label 
  */
export const backLable = (value: string, list: any[]) => {
    let valueList: any[] = [];
    let label: string = '';
    list.forEach(item => {
        let tagValues = item?.tagValues;
        valueList.push(...tagValues)
    })
    valueList.forEach(element => {
        if (value === element?.tagValueId) {
            label = element?.tagValue;
        }
    });
    return label;
}

/**
 * 根据数组返回某个字段的值 用逗号拼接
 */
export const getArgsSplicing = (data: any[], argsName: string) => {
    let arr: any[] = []
    data.length && data.forEach((item) => {
        arr.push(item[argsName])
    })
    return arr.join(',')
}

/**
 *  字符串转数组 
 */

export const getArgsNameObjArray = (str: string, argsName: string) => {
    let arr = str.split(',');
    let _arr: any[] = []
    arr.forEach((item) => {
        _arr.push({ [argsName]: item })
    })
    return _arr;
}

/**
 * 数据非空验证
 * @desc 验证数据是否为 null undefined [] {} ''
 * @param data
 * @return {boolean}
 */
export const isEmpty = (data: string | null | undefined) => {
    if (data === null) return true
    if (data === undefined) return true
    if (Object.prototype.toString.call(data) === '[object Array]') return data.length === 0
    if (Object.prototype.toString.call(data) === '[object Object]') return Object.keys(data).length === 0
    if (typeof data === 'string') return data.trim() === ''
    return false
}



/**
 * 递归数据返回选中ID数组
 */
export const getSelectedsArray = (list: any[], key: string, value: string, childrenKey: string, alias?: string, isMenu?: boolean) => {
    let result: string[] = [];
    list.forEach((item, index) => {
        let children = item[childrenKey];
        if (item.type === 1) {
            if (item[key] === value) {
                if (children.length) {
                    children.forEach((_item: any) => {
                        result.push(...getSelectedsArray(children, key, _item[key], childrenKey, alias, true))
                    });
                }
            } else {
                result.push(...getSelectedsArray(children, key, value, childrenKey, alias, false))
            }
        }
        if (item.type === 2) {
            if (isMenu) {
                alias ? result.push(item[alias]) : result.push(item[key])
            } else {
                if (item[key] === value) {
                    alias ? result.push(item[alias]) : result.push(item[key])
                }
            }
        }
    })

    return Array.from(new Set(result));;
}
/**
 * 递归修改数据
 */
export const recursionEditData = (list: any[], childrenKey: string) => {
    list.length && list.forEach((item, index) => {
        let children = item[childrenKey];
        if (item.type === 1) {
            item['label'] = `${item?.name}`
            item['value'] = `${item?.uuid}~${item?.id}`
            recursionEditData(children, 'children');
        }
        if (item.type === 2) {
            item['label'] = `${item?.name}(${item?.userName})`
            item['value'] = `${item?.uuid}~${item?.id}`
        }
    })
    return list;
}


// 回显组织架构选中数据 
export const handleDealTreeChooseData = (activekeys: string[], treeData: any[]) => {
    if (!activekeys) return [];
    const list: any[] = [];
    const handleGetChossItem = (treeData: any[]) => {
        for (let i = 0; i < treeData.length; i++) {
            const element = treeData[i];
            if (activekeys.includes(element.id)) {
                const arr = list.filter(el => element.id === el.id);
                if (!arr.length) {
                    list.push({
                        disabled: undefined,
                        halfChecked: undefined,
                        id: element.id,
                        label: element.name,
                        value: element.uuid
                    })
                }
            } else {
                if (element.children && element.children.length) {
                    handleGetChossItem(element.children);
                }
            }
        }
    }
    handleGetChossItem(treeData);
    return list;
}

// 根据角色Id返回角色名称
export const handleGetRoleName = (list: any[], roleId: string) => {
    let roleName = '';
    console.log(list, 'list');

    if (list.length) {
        list.forEach(el => {
            const { roles } = el;
            if (roles && roles.length) {
                roles.forEach((item: { value: string; label: string }) => {
                    if (item.value === roleId) {
                        roleName = item.label;
                    }
                })
            }
        });
    }
    console.log(roleName, 'roleName');

    return roleName;
}