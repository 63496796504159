/**
 * @ Author: Your name
 * @ Create Time: 2023-04-04 09:31:08
 * @ Modified by: Your name
 * @ Modified time: 2025-01-15 14:21:47
 * @ Description: 聊天 Im
 */
import { checkShowRule } from "pages/layout/publicFunc";
import { requestRecordModel } from 'model/chatModel'
import request from "server";
/**
 * 获取聊天用户列表
 */
export const getChatUserList = (data: {
  myId: string,
  isGroup: number,
  page?: {
    pageNum?: number,
    pageSize?: number
  },
  sessionKey?: string,
  friendId?: string,
  keyWord?: string,
  tagValueId?: string,
  unReadSort?: number,
}) => {
  return request({
    url: `/kefu-im/sessions/pageMyChatSession`,
    method: 'post',
    data
  })
}

/**
 * 获取用户聊天记录
 */
export const getChatRecord = (data: requestRecordModel) => {
  return request({
    // url: `/kefu-im/chatMessage/pageMyChatMessage`,
    url: `/kefu-im/chatMessage/listChatMsgPage`,
    method: 'post',
    data
  })
}

// 转接对象
export const listConsult = (data: object) => {
  return request({
    url: `/kefu-im/change/listConsult`,
    method: 'post',
    data
  })
}

// 招聘发起会话
export const createChat = (data: any) => {
  return request({
    url: `/kefu-im/sessions/buildRecruitmentServiceSession`,
    method: 'post',
    data
  })
}
// 招聘发起会话
export const buildServiceSession = (data: any) => {
  return request({
    url: `/kefu-im/sessions/buildServiceSession`,
    method: 'post',
    data
  })
}

// im发起会话
export const imCreateChat = (data: any) => {
  return request({
    url: `/kefu-im/sessions/createSession`,
    method: 'post',
    data
  })
}
// 自主报名聊天 
export const imAutoSignUpChat = (data: any) => {
  return request({
    url: `/kefu-im/sessions/getSignUpRecruitmentSession`,
    method: 'post',
    data
  })
}
// 群聊成员
export const getGroupUser = (groupId: string | number) => {
  return request({
    url: `/kefu-im/chatGroup/queryGroupWithUser/${groupId}`,
    method: 'get',
  })
}

// 内部用户获取求职者信息
export const queryAccountInfoByLoginId = (data: any) => {
  return request({
    url: `/member/accountManage/queryAccountInfoByLoginId`,
    method: 'post',
    data
  })
}

export const queryAccountInfoByLoginIdNew = (data: any) => {
  return request({
    url: `/member/accountManage/queryAccountInfoByLoginIdNew`,
    method: 'post',
    data
  })
}

/**
 * 用户信息
 */

export const getCustomerInfo = (data: { mainId: string, mainType: number }) => {
  return request({
    url: `/recruitment/wecomExternal/memberInfo/head`,
    method: 'post',
    data
  })
}

// 新增记录(im聊天框发送邀请报名) 
export const addInviteRecord = (data: any) => {
  return request({
    url: `/recruitment/recruitmentSignUpInviteRecord/addInviteRecord`,
    method: 'post',
    data
  })
}

// 获取用户已有的标签
export const getUserLabel = (memberLoginId: string) => {
  return request({
    url: `/member/memberTag/getMemberTagHistory/${memberLoginId}`,
    method: 'get',
  })
}

// 修改用户标签
export const getMemberTagHistory = (memberLoginId: string) => {
  return request({
    url: `/member/memberTag/getMemberTagHistoryNew/${memberLoginId}`,
    method: 'get',
    data: {}
  })
}

// 获取用户已有的行为
export const listActionRecordPageByTime = (data: any) => {
  return request({
    url: `/member-datahub/memberActionRecord/listActionRecordPageByTime`,
    method: 'post',
    data
  })
}

// 今日在招/预招订单
export const getOrderList = (data: any) => {
  return request({
    url: `/recruitment/myRec/sendOrder`,
    method: 'post',
    data
  })
}

// 订单详情
export const getOrderDetail = (orderId: string) => {
  return request({
    url: `/customer/order/v2/member/detail/${orderId}`,
    method: 'get',
  })
}

// 推送记录
export const userCustomMessage = (data: any) => {
  return request({
    url: `/member/serviceMessage/userCustomMessage`,
    method: 'post',
    data
  })
}

export const getMemberCount = (data: any) => {
  return request({
    url: `/member/serviceMessage/getMemberCount`,
    method: 'post',
    data
  })
}

// 确认推送
export const sendCustomMessage = (data: any) => {
  return request({
    url: `/member/serviceMessage/sendCustomMessage`,
    method: 'post',
    data
  })
}

// 上传人员名单
export const customListImport = (data: any) => {
  return request({
    url: `/member/serviceMessage/customListImport`,
    method: 'post',
    data
  })
}

// 已读未读人员
export const getReadStatusMembers = (data: any) => {
  return request({
    url: `/member/serviceMessage/getReadStatusMembers`,
    method: 'post',
    data
  })
}
// 撤回消息
export const recallCustomMessage = (data: any) => {
  return request({
    url: `/member/serviceMessage/recallCustomMessage`,
    method: 'post',
    data
  })
}

// 添加跟踪记录
export const saveTrackingRecords = (data: any) => {
  return request({
    url: `/member/operate/saveTrackingRecords`,
    method: 'post',
    data
  })
}
// 跟踪记录
export const getTrackingRecords = (data: any) => {
  return request({
    url: `/member/operate/getTrackingRecords`,
    method: 'post',
    data
  })
}
// 推送列表群
export const getUserCustomersDeptEmploymentMode = () => {
  return request({
    url: `/customer/customerService/getUserCustomersDeptEmploymentMode`,
    method: 'get',
  })
}

// 跟新预计到厂时间
export const updateToFactoryTime = (data: any) => {
  return request({
    url: `/recruitment/wecomExternal/memberInfo/updateToFactoryTime`,
    method: 'post',
    data
  })
}
/**
 * 客诉工单
 * @param data 
 * @returns 
 */
// 用户信息
export const infoByMemberLoginId = (loginId: string) => {
  return request({
    url: `/member/memberLogin/infoByMemberLoginId/${loginId}`,
    method: 'get',
  })
}

export const getListByLoginId = (loginId: string) => {
  return request({
    url: `/system/complaint/getListByLoginId/${loginId}`,
    method: 'post',
  })
}


/**
 * 岗位收藏
 */
export const getCollectionListByMemberLoginId = (data: any) => {
  return request({
    url: `/member-integral/collection/getCollectionListByMemberLoginId`,
    method: 'post',
    data,
  })
}
// 求职旅程
export const memberSignUpTrack = (data: any) => {
  return request({
    url: `/recruitment/wecomExternal/memberInfo/memberSignUpTrack`,
    method: 'post',
    data,
  })
}

export const memberSignUpTrackDetail = (data: any) => {
  return request({
    url: `/recruitment/wecomExternal/memberInfo/memberSignUpTrackDetail`,
    method: 'post',
    data,
  })
}

/**
 * 快捷回复
 */
// 组列表
export const getScriptGroupList = () => {
  return request({
    url: `/kefu-im/scriptGroup/getList`,
    method: 'get',
  })
}
// 话术列表
export const getScriptList = (data: any) => {
  return request({
    url: `/kefu-im/script/getList`,
    method: 'post',
    data
  })
}
// tab页签权限
export const getChatMenu = () => {
  return request({
    url: `/system/complaint/getChatMenu`,
    method: 'post',
  })
}

/**
 * 客服状态
 */

// 获取客服闲忙状态
export const getKefuStatus = () => {
  return request({
    url: `/kefu-im/chatUser/getKefuStatus`,
    method: 'get',
  })
}

// 保存客服闲忙状态
export const saveKefuStatus = (data: any) => {
  return request({
    url: `/kefu-im/chatUser/saveKefuStatus`,
    method: 'post',
    data,
  })
}

// IM-推送-模板

// 模板列表
export const getMessageTemplateList = (data: any) => {
  return request({
    url: `/recruitment/messageTemplate/getMessageTemplateList`,
    method: 'post',
    data,
  })
}

// 新增模板
export const saveOrUpdateMessageTemplate = (data: any) => {
  return request({
    url: `/recruitment/messageTemplate/saveOrUpdateMessageTemplate`,
    method: 'post',
    data,
  })
}

//删除模板

export const deleteMessageTemplateByTemplateId = (templateId: string) => {
  return request({
    url: `/recruitment/messageTemplate/deleteMessageTemplateByTemplateId/${templateId}`,
    method: 'post',
  })
}


export const handleGetChatRecord = (params: requestRecordModel) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { customerDeptId, employmentMode, page } = params;
      const res = params?.requestType === 2 ? await userCustomMessage({ customMsgType: 5, departmentId: customerDeptId, employmentMode: employmentMode, page }) : await getChatRecord(params);
      const { data } = res;
      const recordList = params?.requestType === 2 ? (res?.data?.rows || []) : data;
      const _recordList = checkShowRule(recordList?.reverse())
      resolve(_recordList);
    } catch (error) {
      reject()
    }

  })
}