import { checkShowRule, showLastMsgFormat, toggleTime, updateChatListPositionNew } from "pages/layout/publicFunc";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { chatListModel, chatRecordModel, requestRecordModel } from 'model/chatModel'
import { getChatRecord, userCustomMessage } from "api/chat";
import { deepCopy, getCookie } from "utils";
import cookie from 'js-cookie';

interface InitModel {
    searchList: chatListModel[];
    list: chatListModel[];
    recordList: chatRecordModel[];
    total: number;
    groupTotal: number,
    active: number;
    userid: string;
    showRecordType: number;
    activeItem: chatListModel | undefined;
    visible: boolean;
    pageNum: number
    loadIcon: boolean;
    isFord: boolean;
    activeTabsUtil: number;
    friendId: string;  //重新存储为sessionKey的值
    loading?: boolean;
    tabIndex: number; // 0个人 1群聊 2推送
    chatId?: string | number;
    groupList?: any[];
    orderData?: any;
    rightType?: number | null;
    tagValueId?: number;
    serviceStatus?: any
}
const initChat: InitModel = {
    list: [], // 所有联系人列表
    searchList: [], // 联系人搜索列表
    recordList: [], // 聊天记录数组
    total: 0, // 总数
    active: 0, // 对话索引
    userid: getCookie('userid') as string,
    showRecordType: 1, // 1 拉取最新记录信息  其他不掉接口  socket 动态更新
    activeItem: undefined, // 选中的对象数据
    visible: false, // 是否展示聊天框
    pageNum: 1,
    loadIcon: true,// 加载更多数据时显示的load
    isFord: true, // 是否折叠工具栏
    activeTabsUtil: 1, // 工具栏选中的tabs
    friendId: '', // 客资带过来的好友Id
    groupTotal: 0,// 群聊统计
    loading: false, //聊天会话加载
    tabIndex: 0, // 0单聊  1群聊 2推送
    chatId: '',//切换聊天框
    groupList: [], // 群聊人员
    orderData: null,
    rightType: null, //1 用户信息  2订单信息  3推送已读未读人群  4 推送-模板
    tagValueId: -1, //个人 - 标签信息筛选
    serviceStatus: null
}

const chatSlice = createSlice({
    name: 'chat',
    initialState: initChat,
    reducers: {
        // 更新friendId
        updateFriendId: (state, action: PayloadAction<string>) => {
            state.friendId = action.payload
        },
        // 更新userid
        updateUserId: (state) => {
            state.userid = getCookie('userid') as string
        },
        // 更新显示的总数
        updateTotal: (state, action: PayloadAction<number>) => {
            state.total = action.payload
        },
        // 群聊未读统计
        updateGroupTotal: (state, action: PayloadAction<number>) => {
            state.groupTotal = action.payload
        },
        // 更新是否折叠工具栏
        updateIsFord: (state, action: PayloadAction<boolean>) => {
            state.isFord = action.payload
        },
        updateChatUser: (state, action: PayloadAction<string | number>) => {
            state.chatId = action.payload
        },
        updateGroupList: (state, action: PayloadAction<any[]>) => {
            state.groupList = action.payload
        },
        // 更新选中
        updateActive: (state, action: PayloadAction<{
            index?: number, activeItem?: chatListModel
        }>) => {
            const { index, activeItem } = action.payload;
            if (index) {
                state.active = index;
                state.recordList = [];
            }
            if (activeItem) {
                state.activeItem = activeItem;
            }
            // 没有聊天会话时
            if (!activeItem) {
                state.activeItem = undefined;
            }
            // 更新未读消息数量
        },
        // 暂存所有人联系列表
        updateList: (state, action: PayloadAction<chatListModel[]>) => {
            state.list = action.payload;
            state.searchList = action.payload;
        },
        // 更新查询联系人列表
        updateSearchList: (state, action: PayloadAction<chatListModel[]>) => {
            state.searchList = action.payload;
        },
        // 更新聊天记录列表
        updateRecordList: (state, action: PayloadAction<chatRecordModel>) => {
            console.log('更新聊天记录数据', 'updateRecordList');
            state.recordList = [...state.recordList, action.payload,];
        },
        // 设置聊天记录数据
        setRecordList: (state, action: PayloadAction<chatRecordModel[]>) => {
            console.log('设置最新聊天记录', 'setRecordList');
            state.recordList = [...action.payload];
        },
        // 更新新已读聊天列表
        updateReadRecordList: (state, action: PayloadAction<{
            list: chatRecordModel[];
            sessionKey: any;
        }>) => {
            console.log('跟新已读聊天列表', action.payload.sessionKey, state.activeItem?.sessionKey, 'updateReadRecordList');
            if (action.payload.sessionKey === state.activeItem?.sessionKey) {
                state.recordList = [...action.payload.list];
            }
        },
        // 是否显示聊天窗口
        updateVisible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
        //在当前聊天对话框 更新未读消息 已经位置
        updateUnReadMsg: (state, action: PayloadAction<{
            item: chatListModel;
            type: number;
        }>) => {
            const { item, type } = action.payload;
            const _list = changeUnReadMsg(deepCopy(state.list), item, type, state.recordList)
            state.list = _list;
            state.searchList = _list;
            // console.log(_list);

        },
        // 跟新tab页签
        updateTabIndex: (state, action: PayloadAction<number>) => {
            state.tabIndex = action.payload;
        },
        // 不在当前聊天框  更新列表显示位置以及消息未读、时间、最近的消息展示
        updateOtherUnReadMsg: (state, action: PayloadAction<{
            lastMsg: chatRecordModel // 最新的聊天记录
            isCurrent: boolean // 是否在当前聊天框
        }>) => {
            const { lastMsg, isCurrent } = action.payload;
            let _list = updateChatListPositionNew(deepCopy(state.list), lastMsg?.sessionKey);
            // console.log(_list, 'updateOtherUnReadMsg');
            // 群聊类型
            const groupChatType = [1, 4]; //chatType 1 4

            _list?.forEach(item => {
                // 好友ID = 消息的发送人ID (单聊)                 // 当其他群收到消息(群聊)
                // if (item.friendId === lastMsg?.senderId || (lastMsg?.chatType === 1 && item.sessionKey === lastMsg?.sessionKey) || (lastMsg?.chatType !=1 && item.sessionKey === lastMsg?.sessionKey)) {
                if (item.sessionKey === lastMsg?.sessionKey) {
                    item['lastestMsg'] = showLastMsgFormat(lastMsg.msgType, lastMsg.content);
                    item['lastesMsgId'] = lastMsg?.id as any;
                    item['lastestTime'] = toggleTime(lastMsg.createTime).time as string;
                    if (!isCurrent) {
                        const { unReadCount } = item;
                        if (groupChatType.indexOf(lastMsg.chatType) >= 0 && lastMsg?.senderId === cookie.get('userid')) return; //自己给自己发消息不提示
                        // if (lastMsg?.chatType === 1 && lastMsg?.senderId === cookie.get('userid')) return; //自己给自己发消息不提示
                        if (lastMsg?.senderId === cookie.get('userid')) return; //自己拨打语音，自己发送客服信息不提示   msgType === 0 || msgType===23 语音接通或不接通时
                        if (lastMsg?.msgType === 21) return;
                        item['unReadCount'] = String(Number(unReadCount) + 1);
                    } else {
                        item['unReadCount'] = '0';
                    }
                }
            });
            state.list = _list as chatListModel[];
            state.searchList = _list as chatListModel[];
        },

        // 撤回消息聊天会话列表跟新(消息已撤回/你已撤回一条消息)
        updateLastMsg: (state, action: PayloadAction<{
            revocationData: any;
            isCurrent?: boolean
        }>) => {
            let sessionList = deepCopy(state.list);
            const { revocationData, isCurrent } = action.payload;
            let index = sessionList.findIndex((el: any) => el?.sessionKey === revocationData?.sessionKey);
            if (index > -1) {

                // 更新会话最后一条显示消息
                if (revocationData?.id === sessionList[index]?.lastesMsgId) { //当撤回的为最新一条数据才跟新
                    if (isCurrent) { //如果是当前会话 撤回消息
                        sessionList[index].lastestMsg = showLastMsgFormat(revocationData?.msgType, '你已撤回一条消息')  //防止与首次进入聊天会话冲突
                    } else {
                        sessionList[index].lastestMsg = showLastMsgFormat(revocationData?.msgType, '消息已撤回');
                    }
                }

                if (!isCurrent) { // 非当前会话
                    const { unReadCount } = sessionList[index];
                    sessionList[index].unReadCount = String(Number(unReadCount) - 1); //撤回 -1
                }
            }

            state.list = sessionList as chatListModel[];
            state.searchList = sessionList as chatListModel[];
        },
        // 更新加载更多显示
        updateLoad: (state, action: PayloadAction<boolean>) => {
            state.loadIcon = action.payload;
        },
        // 会话刷新
        updateLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        // 更新分页
        updatePage: (state, action: PayloadAction<number>) => {
            state.pageNum = action.payload;
        },
        // 更新聊天记录类型 控制滚动条位置
        updateShowRecordType: (state, action: PayloadAction<number>) => {
            state.showRecordType = action.payload;
        },
        // 更新工具栏切换选择
        updateActiveTabsUtil: (state, action: PayloadAction<number>) => {
            state.activeTabsUtil = action.payload;
        },
        // 清空聊天列表
        clearRecordList: (state) => {
            console.log('清空聊天数据', 'clearRecordList');
            state.recordList = []
        },
        // 重置聊天框数据
        resetChat: (state) => {
            state.list = []; // 所有联系人列表
            state.searchList = []; // 联系人搜索列表
            state.recordList = []; // 聊天记录数组
            // state.total = 0; // 总数
            state.active = 0; // 对话索引
            state.userid = '';
            state.showRecordType = 1; // 1 拉取最新记录信息  其他不掉接口  socket 动态更新
            state.activeItem = undefined; // 选中的对象数据
            state.visible = false; // 是否展示聊天框
            state.pageNum = 1;
            state.loadIcon = true; // 加载更多数据时显示的load
            state.isFord = true; // 是否折叠工具栏
            state.activeTabsUtil = 1;  // 工具栏选中的tabs
            state.rightType = null; //清空右边工具栏
            state.chatId = '';
            state.tabIndex = 0; //选中tab
            state.serviceStatus = null;
        },

        // 发送报名表
        upeateOrderData: (state, action: PayloadAction<any>) => {
            state.orderData = action.payload;
        },

        // 右侧工具栏展示的类型
        updateRightType: (state, action: PayloadAction<number | null>) => {
            state.rightType = action.payload;
        },
        updateTagValueId: (state, action: PayloadAction<number>) => {
            state.tagValueId = action.payload;
        },
        updateServiceStatus: (state, action: PayloadAction<number>) => {
            state.serviceStatus = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getRecord.fulfilled, (state, action) => {
            console.log(3333, 'state.recordList');
            let _recordList = checkShowRule(action.payload?.reverse())
            state.loadIcon = true
            if (action.payload.length < 20) {
                state.loadIcon = false
            }
            state.recordList = [..._recordList, ...state.recordList];
        })
    },


})

export const getRecord = createAsyncThunk(
    "chat/getRecord",
    async (params: requestRecordModel) => {
        try {
            const { customerDeptId, employmentMode, page } = params;
            const res = params?.requestType === 2 ? await userCustomMessage({ customMsgType: 5, departmentId: customerDeptId, employmentMode: employmentMode, page }) : await getChatRecord(params);
            const { data } = res;
            return params?.requestType === 2 ? (res?.data?.rows || []) : data;
        } catch (error) {

        }
    }
)

export const changeUnReadMsg = (
    list: chatListModel[], // 原始聊天人数列表
    item: chatListModel, // 
    type: number,
    recordList?: chatRecordModel[],
) => {
    const _list = deepCopy(list);
    const lastMsg = recordList && recordList?.length && recordList[recordList?.length - 1].content;
    const lastMsgId = recordList && recordList?.length && recordList[recordList?.length - 1]?.id;
    const lastestTime = recordList && recordList?.length && recordList[recordList?.length - 1].createTime
    const msgType = recordList && recordList?.length && recordList[recordList?.length - 1].msgType
    _list.forEach((element: {
        unReadCount?: string;
        friendId?: string;
        oldLastestMsg?: string;
        lastestMsg?: string;
        lastestTime?: string;
        lastesMsgId?: string;
        sessionKey?: string;

    }) => {
        const { sessionKey } = element
        if (item?.sessionKey === (sessionKey as any) && type === 1) {
            element['unReadCount'] = '0';
            // 更新列表最后消息显示
            if (lastMsg) {
                if (msgType !== -1) {  //点击会话 当前会话最有一条为撤回的消息不展示content
                    element['lastestMsg'] = showLastMsgFormat(msgType, lastMsg);
                }

                element['lastesMsgId'] = lastMsgId as any
            }
            if (lastestTime) {
                element['oldLastestMsg'] = lastestTime;
                element['lastestTime'] = toggleTime(lastestTime).time;
            }
        }
    })
    // console.log(_list, '_list');

    return _list
}
export const {
    updateFriendId,
    updateUserId,
    updateTotal,
    updateGroupTotal,
    updateActive,
    updateList,
    updateSearchList,
    updateVisible,
    updateRecordList,
    updateReadRecordList,
    updateUnReadMsg,
    updateLastMsg,
    updateOtherUnReadMsg,
    resetChat,
    updateLoad,
    updateLoading,
    updatePage,
    updateShowRecordType,
    updateIsFord,
    updateActiveTabsUtil,
    clearRecordList,
    updateTabIndex,
    updateChatUser,
    updateGroupList,
    upeateOrderData,
    updateRightType,
    updateTagValueId,
    setRecordList,
    updateServiceStatus,
} = chatSlice.actions
export default chatSlice.reducer;