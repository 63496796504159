import moment from 'moment';
import { siteTabEnum } from './sitTab'
export enum dictionaryId {
    areaId = '1467674285245075458', //区域id
    paidModel = '1430047141177618434', //计薪模式(薪酬模式)
    employmentMode = '1430046061828050945', // 用工模式
    transportationWay = '1467047911446679554',  //输送方式
    recruitmentPattern = '1467047911446679555',  // 招聘模式
    registrationWay = '1467047911446679556',  // 报名方式
    postType = "1430006041456947201", //岗位类型
    nation = "1430008920435650561", //民族
    personnelType = "1430000212628491130", // 人员类型
    complaintClassify = '1495658020355411969', //投诉分类
    questionType = '1495659445055623170',  //问题类型
    blackType = '1495660661504446465', //黑名单
    urgencyType = '1495669929834156033',  //紧急程度
    complaintType = '1495661701071081473',  //投诉状态
    satisfactionType = '1495665673227964417',  //求职满意度
    signType = '1495665127939084290',  //标记方式
}
export enum operationTypeEnum {
    add = 'add',
    edit = 'edit'
}
// 用户状态
export const userStatus = [
    // { label: "全部", value: null },
    { label: "已报名", value: 10 },
    { label: "门市登记", value: 20 },
    { label: "入场候车", value: 30 },
    { label: "门市出场", value: 40 },
    { label: "已上车", value: 50 },
    { label: "到厂登记", value: 60 },
    { label: "未接到", value: 61 },
    { label: "已录取", value: 70 },
    { label: "未录取", value: 80 },
    { label: "完成签订合同", value: 90 },
    { label: "未到岗", value: 99 },
    { label: "到岗", value: 100 },
    { label: "在职", value: 105 },
    { label: "离职", value: 110 },
]

export const fskStatus = [
    { label: "未录入", value: 0 },
    { label: "已录入", value: 1 },
]
//门店现场管理筛选条件
export function chooseStatus(type?: string) {
    let chooseStatus = [
        { label: "姓名", value: 0 },
        { label: "身份证号", value: 1 },
        { label: "求职企业", value: 2 },
        { label: "招聘人员", value: 3 },
        { label: "手机号", value: 4 },
    ]
    if (type === siteTabEnum.storeRegister) {
        let arr = [...chooseStatus];
        arr.push({ label: "登记人", value: 5 });
        return arr

    } else if (type === siteTabEnum.brushCar) {
        let arr = [...chooseStatus];
        arr.unshift({ label: "车牌号码", value: 7 });
        arr.push({ label: "上车操作人", value: 6 });
        return arr;
    } else {
        return [...chooseStatus]
    }
}
// 登记方式
export const registerWay = [
    // { label: "全部", value: null },
    { label: "刷证登记", value: 0 },
    { label: "扫码登记", value: 1 },
    { label: "人证登记", value: 2 },
]
// 登记重复
export const registerRepeatStatus = [
    { label: "去重登记", value: 0 },
    { label: "全部登记", value: 1 },
]
// 发车状态
export const departStatus = [
    // { label: "全部", value: null },
    { label: "未发车", value: 0 },
    { label: "已发车", value: 1 }
]

// 输送类型 
export const transportType = [
    { label: "输送至工厂", value: 2 },
    { label: "输送至门店", value: 1 },
    { label: "输送至宿舍", value: 3 }
]
// 报销状态，，-2：已撤回，-1：不通过，0：未提交， 1：审批中，2：审批通过，3：已放款，
export const expensesStatus = [
    { label: "不通过", value: -1 },
    { label: "未提交", value: 0 },
    { label: "审批中", value: 1 },
    { label: "审批通过", value: 2 },
    { label: "已放款", value: 3 }
]
export const newExpensesStatus = [
    { label: "审批中", value: 1 },
    { label: "已通过", value: 2 },
    { label: "已驳回", value: 3 },
    { label: "已撤销", value: 4 }
]

// 1：门店经理，2：区域经理，3：副总，4：财务，5：完成
export const approvalStep = [
    { label: "门店经理", value: 1 },
    { label: "区域经理", value: 2 },
    { label: "副总", value: 3 },
    { label: "财务", value: 4 },
    { label: "完成", value: 5 }
]
// 上车方式
export const getonBus = [
    // { label: "全部", value: null },
    { label: "刷证上车", value: 2 },
    { label: "扫码上车", value: 1 },
]
// 上车登记状态
export const getonBusStauts = [
    // { label: "全部", value: null },
    { label: "上车", value: 1 },
    { label: "门店下车", value: 2 },
    { label: "到厂下车", value: 3 },
    { label: "取消上车", value: 4 },
]
// 性别
export const sexStauts = [
    { label: "男", value: '1' },
    { label: "女", value: '2' },
    { label: "未知", value: '0' },
]
// 性别
export const sexStautsNum = [
    { label: "男", value: 1 },
    { label: "女", value: 2 },
    { label: "未知", value: 0 },
]

export const sexNum = [
    { label: "男", value: 1 },
    { label: "女", value: 2 },
]
// 报名表状态: 0-待确认, 1-已确认，2.已失效,3.已使用
export const signupStatus = [
    { label: "待确认", value: 0 },
    { label: "已确认", value: 1 },
    { label: "已失效", value: 2 },
    // { label: "已使用", value: 3 },
]
// 门市接收
export const receiveStatus = [
    { label: "本门市报名", value: 0 },
    { label: "本门市待接收", value: 1 },
]
// 报名重复
export const repeatStatus = [
    { label: "全部报名", value: 0 },
    { label: "重复报名", value: 1 },
    { label: "到厂归属报名", value: 2 },
]
// 安置类型 
export const arrangerType = [
    { label: "门店输送安置", value: 0 },
    { label: "客服接待安置", value: 1 },
]
// 报名状态
export const signUpStatusOptions = [
    { label: "待确认", value: 0 },
    { label: "已确认", value: 1 },
    { label: "已失效", value: 2 },
]
// 是否阅读须知 
export const readRequireFlagOptions = [
    { label: "未阅读", value: 0 },
    { label: "已阅读", value: 1 },
]
// 车辆状态 车辆状态,1:空闲，2：行驶中"
export const carStatus = [
    { label: "空闲", value: 1 },
    { label: "行驶中", value: 2 },
]
// 来源渠道
// 0：微信小程序-华辉hr；1：快手小程序-华辉人力；2：快手小程序-华辉人力资源；3：华辉人力资源-华辉人力连锁；4：快手小程序-华辉-工友汇；5：快手小程序-我的幸运工友
export const channel = [
    { label: "微信小程序-华辉hr", value: 0 },
    { label: "快手小程序-华辉人力", value: 1 },
    { label: "快手小程序-华辉人力资源", value: 2 },
    { label: "华辉人力资源-华辉人力连锁", value: 3 },
    { label: "快手小程序-华辉-工友汇", value: 4 },
    { label: "快手小程序-我的幸运工友", value: 5 },
]
// 分配状态
// 1：已分配，0：未分配
export const distributionStatus = [
    { label: "未分配", value: 0 },
    { label: "已分配", value: 1 },
]
// 客资管理分配状态
// true：分配中，false：暂停
export const allocationStatus = [
    { label: "分配中", value: true },
    { label: "暂停", value: false },
]
// 使用状态
export const useStatus = [
    { label: "应用中", value: 0 },
    { label: "停止", value: 1 },
]
// 应用规则
export const useRule = [
    { label: "顺序轮流", value: 0 },
    { label: "固定显示", value: 1 },
]
// 操作类型
export const operateStatus = [
    { label: "报名新增", value: 1 },
    { label: "状态变更", value: 2 },
    { label: "报名失效", value: 3 },
    { label: "企业变更", value: 4 },
    { label: "招聘员变更", value: 5 },
]
// 发布状态
export const publishStatus = [
    { label: "未启用", value: 0 },
    { label: "发布中", value: 1 },
]
// 发布平台
export const publishPlatforms = [
    { label: "微信小程序", value: 1 },
    { label: "快手小程序", value: 2 },
    { label: "App", value: 3 },
]

// 发车状态
export const applyStatus = [
    { label: "未发车", value: 1 },
    { label: "已发车", value: 2 },
]
export const advanceList = [
    { value: "/layout/advanceApproval", label: "驻厂客服审核" },
    { value: "/layout/advanceApprovalRecheck", label: "客服复审" },
    { value: "/layout/advanceApprovalFinancial", label: "财务审核" },
    { value: "/layout/advanceApprovalLending", label: "财务放款" },
]


// 出入状态
export const brakeType = [
    { label: "进场", value: 0 },
    { label: "出场", value: 1 },
]

// 执行方式
export const executeType = [
    { label: "立即执行", value: 0 },
    { label: "定时发送", value: 1 },
]
// 人员筛选方式
export const personnelType = [
    { label: "按人员状态", value: 0 },
    { label: "自定义名单", value: 1 },
]
// 消息通道
export const channelList = [
    { label: "求职者app", value: 1 },
    { label: "手机短信", value: 2 },
]
// 计划状态
export const planStatus = [
    { label: "草稿", value: 0 },
    { label: "待发送", value: 1 },
    { label: "发送中", value: 2 },
    { label: "已完成", value: 3 },
]
// 计划状态
export const servicePlanStatus = [
    { label: "草稿", value: 0 },
    { label: "发送中", value: 1 },
    { label: "已发送", value: 2 },
]
// 时间类型
export const timeTypeOptions = [
    { label: "入库时间", value: 1 },
    { label: "最新报名时间", value: 2 },
    { label: "最新入职时间", value: 3 },
    { label: "最新离职时间", value: 4 },
    { label: "最新活跃时间", value: 5 },
    { label: "app注册时间", value: 6 },
]
export const timeNewOptions = [
    { label: "入库时间", value: 4 },
    { label: "最新报名时间", value: 1 },
    { label: "最新入职时间", value: 2 },
    { label: "最新离职时间", value: 3 },
    { label: "最新活跃时间", value: 5 },
    { label: "app注册时间", value: 6 },
]
// 时间类型
export const serviceTimeType = [
    { label: "报名时间", value: 1 },
    { label: "接到时间", value: 2 },
    { label: "录取时间", value: 3 },
    { label: "报道时间", value: 4 },
    { label: "入职时间", value: 5 },
    { label: "离职时间", value: 6 },
]
//  采集方式  0：系统自动 1：手动导入，2：客资调配，3：存量用户，4：回收池
export const collectionType = [
    { label: "系统自动", value: 0 },
    { label: "手动导入", value: 1 },
    { label: "客资调配", value: 2 },
    { label: "存量用户", value: 3 },
    { label: "回收池", value: 4 },

]

// 推广状态
export const promotionStatus = [
    { label: "停止", value: 0 },
    { label: "投放中", value: 1 },
]
// 计划状态
export const promotionPlanStatus = [
    { label: "停止", value: 0 },
    { label: "投放中", value: 1 },
]
// 工价状态
export const priceStatus = [
    { label: "无变更", value: 0 },
    { label: "已变更", value: 1 },
]

// 发布状态
export const publishStatus2 = [
    { label: "未发布", value: 0 },
    { label: "已发布", value: 1 },
    { label: "未配置", value: 2 }
]
// 审核状态
export const viewStatus = [
    { label: "待发布", value: 0 },
    { label: "审核中", value: 1 },
    { label: "已通过", value: 2 },
    { label: "已驳回", value: -1 },
]
// 发布渠道
export const ditchStatus = [
    { label: "平台外招", value: 1 },
    { label: "平台直招", value: 0 },
]
// 客资类型
export const guestInfoType = [
    { label: "企微好友", value: 1 },
]

// 推广报表 门市下发 客资类型
export const resourceType = [
    { label: "企微好友", value: 1 },
    { label: "手机号码", value: 0 },
]
// 渠道
export const channelResourceType = [
    { label: "企微好友", value: 1 },
    { label: "手机号码", value: 0 },
]

// 宿舍管理  --是否禁用
export const disableStatus = [
    // { label: "禁用", value: 0 },
    { label: "启用", value: 1 },

]

// 房间管理  --是否禁用
export const roomDisableStatus = [
    { label: "禁用", value: 0 },
    { label: "启用", value: 1 },

]

// 房间状态
export const roomStatus = [
    { label: "全部", value: 0, name: "allRoomCount" },
    { label: "空房", value: 1, name: "emptyRoomCount" },
    { label: "未满", value: 2, name: "noFullRoomCount" },
    { label: "满房", value: 3, name: "fullRoomCount" },
    { label: "禁用", value: 4, name: "disableRoomCount" },
]

// 换宿类型
export const changeBuildType = [
    { label: "区域内换宿", value: 1 },
    { label: "区域外换宿", value: 2 },
]
// 入住人员-入住状态
export const staffStatus = [
    { label: "在宿", value: 1 },
    { label: "离宿", value: 2 },
]
// 入住公约-公约状态
export const announceStatus = [
    { label: "未同意", value: 0 },
    { label: "已同意", value: 1 },
]


// 床位状态  0：空闲 ，2：在住，3：申请中
export const bedStatus = [
    { label: "空闲", value: 0 },
    // { label: "临时占位", value: 1 },
    { label: "在住", value: 2 },
    { label: "入住申请中", value: 3 },
    // { label: "维修", value: 4 },
]

export const bedEnable = [
    { label: "禁用", value: 0 },
    { label: "启用", value: 1 },
]

// 入住规则
export const accommodationRule = [
    { label: "满员入住", value: 0 },
    { label: "留1个行李床", value: 1 },
    { label: "留2个行李床", value: 2 },
]
// 退宿类型（1：离职，2：外宿，3：转正）
export const outType = [
    { label: "离职", value: 1 },
    { label: "外宿", value: 2 },
    { label: "转正", value: 3 },
]

// 报修状态
export const repairStatus = [
    { label: "已取消", value: 0 },
    { label: "待维修", value: 1 },
    { label: "维修中", value: 2 },
    { label: "待验收", value: 3 },
    { label: "已完成", value: 4 },
]

// 预警类型
export const abnormalType = [
    { label: "报名次数预警", value: 1 },
    { label: "到店次数预警", value: 2 },
    { label: "上车次数预警", value: 3 },
    { label: "花费车费预警", value: 4 },
    { label: "车费返费预警", value: 5 },
    { label: "入职次数预警", value: 6 },
    { label: "到厂次数预警", value: 7 },
    { label: "疑是蹭车预警", value: 8 },
    { label: "同门店多次上车预警", value: 12 },
    { label: "多门店多次上车预警", value: 13 },
    { label: "疑似骗车补预警", value: 14 },
    { label: "在职收益预警", value: 15 },

    { label: "商品兑换金额预警", value: 18 },
    { label: "商品兑换数量预警", value: 19 },
    { label: "星辉值消耗预警", value: 20 },
]
// 违纪类型
export const violationsType = [
    { label: "员工违纪", value: 1 },
    { label: "安全事件", value: 2 },
    { label: "重大异常", value: 3 },
    { label: "其他事件", value: 4 },
]

// 违纪类型启用状态
export const violationsStatus = [
    { label: "禁用", value: 0 },
    { label: "启用", value: 1 },
]

// 应用平台
export const adPlatforms = [
    { label: "华辉人力app", value: "1" },
    { label: "华辉HR（微信小程序）", value: "2" },
]

// 开屏广告状态
export const adStatus = [
    { label: "应用", value: 2 },
    { label: "禁用", value: 1 },
]
// 审核状态
export const auditStatus = [
    { label: "审核中", value: 0 },
    { label: "审核通过", value: 1 },
    { label: "审核不通过", value: 2 },
]
// 是否注册app
export const registerApp = [
    { label: "否", value: 0 },
    { label: "是", value: 1 },
]
// 是否注册app
export const registerAppList = [
    { label: "未注册", value: 0 },
    { label: "已注册", value: 1 },
]
// 
export const linkType = [
    { label: "站内跳转", value: 1 },
    { label: "链接地址", value: 2 },
    { label: "活动地址", value: 3 },
]

// 开屏广告页面类型
export const pageList = [
    { label: "首页", value: "1" },
    { label: "赚米粒", value: "2" },
    { label: "商城", value: "3" },
    { label: "消息", value: "4" },
    { label: "我的", value: "5" },
    { label: "报名记录", value: "6" },
    { label: "在职记录", value: "7" },
    { label: "入职资料", value: '8' },
    { label: "我的薪资", value: "9" },
    { label: "我的预支", value: "10" },
    { label: "电子合同", value: "11" },
    { label: "我的宿舍", value: "12" },
    { label: "星辉值任务", value: "13" },
    { label: "工厂介绍", value: "14" },
    { label: "计工时", value: "15" },
    { label: "投诉建议", value: "16" },
    { label: "帮助中心", value: "17" },
    { label: "直播广场", value: "18" },
    { label: "专题活动", value: "19" },
    { label: '实名认证', value: '23' },
    { label: "短剧频道", value: "20" },
    { label: "短剧第一集", value: "21" },
    { label: "游戏大厅", value: "22" },
]

// 站内跳转页面为以下值不需要层级
export const noSpaceVal = ['1', '2', '4', '5', '24'];
export const mutilSpaceVal = ['21'];
export const activityIdVal = ['19', '35'];

export const stylesOptions = [
    { label: '弹窗广告', value: 0 },
    // { label: '开屏广告', value: 1 },
]

export const ruleStatus = [
    { label: "应用中", value: 1 },
    { label: "终止", value: 2 },
]
export const reimburseStatus = [
    { label: "按里程计费", value: 1 },
    { label: "固定金额报销", value: 0 },
]

export const followedInfo = [
    { label: "无法联系", value: 0 },
    { label: "求职正常进行中", value: 1 },
    { label: "暂时不去考虑中", value: 2 },
    { label: "不符合招聘要求", value: 3 },
    { label: "咨询了解", value: 4 },
    { label: "已有招聘员", value: 5 },
]

export const followedOptions = [
    { label: "报名意向低", value: 0 },
    // { label: "报名意向中", value: 1 },
    { label: "报名意向高", value: 2 },
    { label: "不符合招聘要求", value: 3 },
    { label: "已有招聘员", value: 4 },
    { label: "无法联系", value: 5 },
    { label: "内部员工报名", value: 6 }

];

export const brokerFlagOptions = [
    { label: "APP", value: 200 },
    { label: "微信小程序", value: 0 },
    { label: "快手小程序", value: 20 },
    { label: "支付宝小程序", value: 400 },
    { label: "官网报名", value: 60 },
    { label: "其他渠道", value: 4 },
]

export const msgSecondTypeOptions = [
    { label: "智能推荐", value: 1 },
]

export const estimateArrivalOnTimeOptions = [
    { label: "是", value: 1 },
    { label: "否", value: 0 },
    { label: "不确定", value: 2 },
]

// 分配类型：1-报名分配；2-在线分配；3-离线分配；4-锁定分配
export const assignTypeOptions = [
    { label: '报名表分配', value: 1 },
    { label: '在线分配', value: 2 },
    { label: '离线分配', value: 3 },
    { label: '锁定分配', value: 4 },
    { label: '预报名次日自动分配', value: 5 }
]

export const serviceCategoryStatus = [
    { label: "禁用", value: 0 },
    { label: "启用", value: 1 },
]

export const channelOptions = [
    { label: '华信营销短信', value: 'huaxin' },
    { label: '阿里云短信', value: 'ali' },
    { label: '联麓营销短信', value: 'lianlu' },
]

//短信类型  1营销类短信；2通知类短信；3服务中心短信；4其他短信；
export const msgTypeOptions = [
    { label: '营销类短信', value: '1' },
    { label: '通知类短信', value: '2' },
    { label: '服务中心短信', value: '3' },
    { label: '其他短信', value: '4' },
]

export const getName = (arr: Array<{ label: string, value: any }>, value: any) => {
    let val = arr.find((el: any) => el.value == value) as any;
    return val?.label || ""
}

export const carType = [
    { label: "上班用车", value: 1 },
    { label: "下班用车", value: 2 },
    { label: "加班用车", value: 3 },
    { label: "面试用车", value: 4 },
    { label: "体检用车", value: 5 },
]

export const timeTypeOptionsCom = [
    { label: '上月', value: 1 },
    { label: '上周', value: 2 },
    { label: '昨日', value: 3 },
    { label: '上季度', value: 4 },
    { label: '近7天', value: 5 },
    { label: '近30天', value: 6 },
    { label: '近60天', value: 7 },
    { label: '近90天', value: 8 },
]


// 加微方式
export const addWayOptions = [
    { label: "未知来源", value: 0 },
    { label: "扫描二维码", value: 1 },
    { label: "搜索手机号", value: 2 },
    { label: "名片分享", value: 3 },
    { label: "群聊", value: 4 },
    { label: "手机通讯录", value: 5 },
    { label: "微信联系人", value: 6 },
    { label: "安装第三方应用时自动添加的客服人员", value: 8 },
    { label: "搜索邮箱", value: 9 },
    { label: "视频号添加", value: 10 },
    { label: "通过日程参与人添加", value: 11 },
    { label: "通过会议参与人添加", value: 12 },
    { label: "添加微信好友对应的企业微信", value: 13 },
    { label: "通过智慧硬件专属客服添加", value: 14 },
    { label: "通过上门服务客服添加", value: 15 },
    { label: "通过获客链接添加", value: 16 },
    { label: "通过定制开发添加", value: 17 },
    { label: "通过需求回复添加", value: 18 },
    { label: "通过第三方售前客服添加", value: 21 },
    { label: "通过可能的商务伙伴添加", value: 22 },
    { label: "通过接受微信账号收到的好友申请添加", value: 24 },
    { label: "内部成员共享", value: 201 },
    { label: "管理员/负责人分配", value: 202 },
]

// 离职方式
export const exitWay = [
    { label: "自离", value: 0 },
    { label: "离职", value: 1 },
    { label: "撤离", value: 2 },
    { label: "转正", value: 3 },
    { label: "退员", value: 4 },
    { label: "其他", value: 5 },
]

// 热词跳转类型
export const skipTypeOptions = [
    { label: "岗位筛选页", value: 1 },
    { label: "非岗位页", value: 2 },
]

export const dealDate = (type: number) => {
    let beginDate = '';
    let endDate = '';
    // 对应 timeTypeOptionsCom
    switch (type) {
        case 1:
            beginDate = moment().subtract(1, 'M').startOf('month').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'M').endOf('month').format("YYYY-MM-DD");
            break;
        case 2:
            beginDate = moment().subtract(1, 'w').startOf('week').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'w').endOf('week').format("YYYY-MM-DD");
            break;
        case 3:
            beginDate = moment().subtract(1, 'd').startOf('days').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').endOf('days').format("YYYY-MM-DD");
            break;
        case 4:
            beginDate = moment().subtract(1, 'Q').startOf('quarter').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'Q').endOf('quarter').format("YYYY-MM-DD");
            break;
        case 5:
            beginDate = moment().subtract(7, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        case 6:
            beginDate = moment().subtract(30, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        case 7:
            beginDate = moment().subtract(60, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        case 8:
            beginDate = moment().subtract(90, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        default:
            break;
    }

    return { beginDate, endDate }
}


export const dealDateNew = (type: number) => {
    let beginDate = '';
    let endDate = '';
    // 1 本月 与前一个相差一个本月
    switch (type) {
        case 1: 
            beginDate = moment().subtract(0, 'M').startOf('month').format("YYYY-MM-DD");
            endDate = moment().subtract(0, 'M').endOf('month').format("YYYY-MM-DD");
            break;
        case 2:
            beginDate = moment().subtract(1, 'M').startOf('month').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'M').endOf('month').format("YYYY-MM-DD");
            break;
        case 3:
            beginDate = moment().subtract(1, 'w').startOf('week').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'w').endOf('week').format("YYYY-MM-DD");
            break;
        case 4:
            beginDate = moment().subtract(1, 'd').startOf('days').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').endOf('days').format("YYYY-MM-DD");
            break;
        case 5:
            beginDate = moment().subtract(1, 'Q').startOf('quarter').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'Q').endOf('quarter').format("YYYY-MM-DD");
            break;
        case 6:
            beginDate = moment().subtract(7, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        case 7:
            beginDate = moment().subtract(30, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        case 8:
            beginDate = moment().subtract(60, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        case 9:
            beginDate = moment().subtract(90, 'd').format("YYYY-MM-DD");
            endDate = moment().subtract(1, 'd').format("YYYY-MM-DD");
            break;
        default:
            break;
    }

    return { beginDate, endDate }
}