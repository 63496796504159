import { configureStore } from '@reduxjs/toolkit'
import userSlice from "./userSlice"
import menuSlice from "./menuSlice"
import loadingSlice from "./loadingSlice"
import dictionarySlice from "./dictionarySlice"
import siteTabSlice from "./siteTabSlice"
import transportSlice from './transportSlice'
import fareStatisticsSlice from "./fareStatisticsSlice";
import dormitorySlice from './dormitorySlice';
import chatSlice from './chatSlice';
import timSlice from './timSlice';
import posterSlice from './posterSlice';
import provisionSlice from './provisionSlice';
import commonSlice from './commonSlice';
import businessSlice from './businessSlice';
import flowSlice from './flowSlice';
import cloudShopSlice from './cloudShopSlice'


export const store = configureStore({
  reducer: {
    user: userSlice,
    menu: menuSlice,
    loading: loadingSlice,
    dictionary: dictionarySlice,
    sitTab: siteTabSlice,
    transport: transportSlice,
    fareStatisticsSlice: fareStatisticsSlice,
    dormitory: dormitorySlice,
    chat: chatSlice,
    tim: timSlice,
    poster: posterSlice,
    provision: provisionSlice,
    common: commonSlice,
    business: businessSlice,
    flow: flowSlice,
    cloudShop: cloudShopSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch