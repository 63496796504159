/**
 * @ Author: Your name
 * @ Create Time: 2023-11-21 14:50:41
 * @ Modified by: Your name
 * @ Modified time: 2024-12-10 10:36:53
 * @ Description: 静态数据
 */

export const titleArr = ['新增', '编辑', '复制', '续签']

export const stepItems = [
  {
    title: '合同信息',
  },
  {
    title: '基础信息',
  },
  {
    title: '薪资设置',
  }
]
export const stepItemsContinuous = [
  {
    title: '合同信息',
  },
  {
    title: '薪资设置',
  }
]
// 计提时长类型
export const recruitmentCommissionDateTypeOptions = [
  { value: 1, label: '按天' },
  { value: 2, label: '按整月' },
  { value: 3, label: '按自然月' },
  { value: 4, label: '指定日期' },
]
// 拟招人数
export const radioIsLimit = [
  { label: '限制', value: 1 },
  { label: '不限', value: 0 }
];
// 性别年龄

export const radioSexLimit = [
  { label: '限制', value: 0 },
  { label: '不限', value: 1 }
];
export const recruitmentCommissionModeOptions = [
  { value: 1, label: '入职条件计提' },
  { value: 2, label: '在职时长计提' }
]

export const payEntityOptions = [
  { value: 1, label: '华辉发薪' },
  { value: 2, label: '企业发薪' }
]
export const durationTypeOptions = [
  { value: 3, label: '按天' },
  { value: 1, label: '按整月' },
  { value: 4, label: '按自然月' },
  { value: 2, label: '指定日期' },
]

// 价格差异
export const priceDiffOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
]
// 培训说明
export const trainOptions = [
  { label: '有', value: 1 },
  { label: '无', value: 0 }
]
// 补贴期限
export const subsidyPeriodOptions = [
  { label: '按天', value: 1 },
  { label: '指定日期', value: 3 },
]

export const orderStatusColors: { [key: string]: string } = {
  '1430000212834011149': '#00C16E',
  '1430000212834011150': '#037EF3',
  '1430000212834011151': '#A3A4A6',

}
// 初始薪酬设置表单设置
export const salaryStructureInitData = {
  cycleNumber: '', // 执行周期数值
  cycleUnit: null, // 执行周期单位：1-按天；2-按月；3-指定日期
  dateBegin: '', // 周期开始日期
  dateEnd: '', // 周期结束日期
  mode: '', // 计薪方式:(sys_dict_type.code:salary_model)
  orderId: '', // 订单id
  orderStructureCycleId: '', // 
  orderStructureId: '', // 薪资结构id
  price: '', // 发单价格
  priceMan: '', // 发单价格男
  priceMax: '', // 综合月薪-最大
  priceMenwomenEnabled: 0, // 发单价格男女差异是否启用 0-不限制, 1-限制
  priceMin: '', // 综合月薪-最小
  priceWomen: '', // 发单价格女
  salaryInfo: '', // 薪资结构
  subsidyConditionInfo: '', // 支付条件、差价补贴条件
}

// 发单 补贴项目
export const subsidyProjectOptions = [
  { label: '餐饮补贴', value: 'foodEnabled' },
  { label: '车费补贴', value: 'fareEnabled' },
  { label: '体检费补贴', value: 'healthCheckEnabled' },
  { label: '稳岗补贴', value: 'awardEnabled' },
  { label: '住宿补贴', value: 'stayEnabled' },
  { label: '夜班补贴', value: 'nightEnabled' },
  { label: '推荐费', value: 'recommendEnabled' },
  { label: '其他补贴', value: 'otherSubsidyEnabled' },
]
export const subsidyAddProjectOptions = [
  { label: '餐饮补贴', value: 'foodEnabled' },
  { label: '体检费补贴', value: 'healthCheckEnabled' },
  { label: '稳岗补贴', value: 'awardEnabled' },
  { label: '住宿补贴', value: 'stayEnabled' },
  { label: '夜班补贴', value: 'nightEnabled' },
  { label: '推荐费', value: 'recommendEnabled' },
  { label: '其他补贴', value: 'otherSubsidyEnabled' },
]
// 订单扣减项目
export const orderDeductionOptions = [
  { label: '住宿费', value: 'lodgingEnabled' },
  { label: '伙食费', value: 'foodEnabled' },
  { label: '水电费', value: 'hydropowerEnabled' },
  { label: '保险费', value: 'insuranceEnabled' },
  { label: '违约金', value: 'breachEnabled' },
  { label: '培训费', value: 'trainingEnabled' },
  { label: '其他', value: 'otherEnabled' },
]
// 处理补贴项数据结构
export const subsidyObject: { [key: string]: any } = {
  'foodEnabled': {
    entryName: 'foodEnabled',
    foodEnabled: 0, // 餐饮补贴-是否启用：0-否, 1-是
    foodAmountUnit: '1430000212834011121', // 餐饮补贴-补贴金额单位
    foodAmount: '', // 餐饮补贴-补贴金额
    foodConditionInfo: '',// 餐饮补贴-支付条件
  },
  'fareEnabled': {
    entryName: 'fareEnabled',
    fareEnabled: 0, // 车费补贴-是否启用：0-否, 1-是
    fareAmount: '', // 车费补贴-补贴金额
    fareConditionInfo: '',// 车费补贴-支付条件
  },
  'healthCheckEnabled': {
    entryName: 'healthCheckEnabled',
    healthCheckEnabled: 0, // 体检补贴-是否启用：0-否, 1-是
    healthCheckAmount: '', // 体检补贴-补贴金额
    healthConditionInfo: '',// 体检补贴-支付条件
  },
  'awardEnabled': {
    entryName: 'awardEnabled',
    awardEnabled: 0, // 稳岗奖励-是否启用：0-否, 1-是
    awardAmountUnit: '1430000212834011120', // 稳岗奖励-补贴金额单位
    awardAmount: '', // 稳岗奖励-补贴金额
    awardConditionInfo: '',// 稳岗奖励-支付条件
  },
  'stayEnabled': {
    entryName: 'stayEnabled',
    stayEnabled: 0, // 住宿补贴-是否启用：0-否, 1-是
    stayAmount: '', // 住宿补贴-补贴金额
    stayAmountUnit: '1430000212834011121', // 住宿补贴-补贴金额单位
    stayConditionInfo: '',// 住宿补贴-支付条件
  },
  'otherSubsidyEnabled': {
    entryName: 'otherSubsidyEnabled',
    otherSubsidyEnabled: 0, // 其他补贴-是否启用：0-否, 1-是
    otherAmount: '', // 其他补贴-补贴金额
    otherConditionInfo: '',// 其他补贴-支付条件
  },
  'nightEnabled': {
    entryName: 'nightEnabled',
    nightEnabled: 0, // 夜班补贴-是否启用：0-否, 1-是
    nightAmount: '', // 夜班补贴-补贴金额
    nightUnit: '1858428018050318338', // 夜班补贴-补贴金额单位 默认元/天
    nightConditionInfo: '', //  夜班补贴-支付条件
  },
  'fareReimbursementEnabled': {
    entryName: 'fareReimbursementEnabled',
    fareReimbursementEnabled: 0,
    fareReimbursementAmount: '', // 车费报销金额
    fareReimbursementMileagePrice: '', // 车费报销-按里程计费单价/每公里
    fareReimbursementOnJobDate: '', // 车费报销-发放条件按在职到指定日期
    fareReimbursementOnJobDays: '', // 车费报销-发放条件按在职天数
    fareReimbursementOnJobType: 1, // 车费报销-发放条件: 0-按在职时长，1-在职到指定日期
    fareReimbursementStartingPrice: '', // 车费报销-按里程计费起步价
    fareReimbursementType: 1, //车费报销-: 0-固定金额，1-按里程计费
    fareWorkDay: '', //车费补贴-约束在职天数
    fareReimbursementPaymentCondition: '',  // 车费报销支付条件
  },
  'recommendEnabled': {
    entryName: 'recommendEnabled',
    fareReimbursementEnabled: 0,
    recommendGrantCondition: 2, // 推荐费-发放条件，1：在职时长，2：在职指定日期
    recommendGrantDate: '', // 推荐费-在职日期
    recommendGrantDays: '', // 推荐费-在职天数
    recommendPaymentCondition: 2, // 推荐费-支付条件，1：在职时长，2：指定日期发放
    recommendPaymentDate: '', // 推荐费-支付日期
    recommendPaymentDays: '', // 推荐费-支付浮动天数
    recommendRewardAmount: '', // 推荐费-补贴金额
  },


}
// 处理扣减项数据结构
export const deductionObject: { [key: string]: any } = {
  'lodgingEnabled': {
    entryName: 'lodgingEnabled',
    lodgingEnabled: 0, //住宿费-是否启用：0-否, 1-是
    lodgingAmount: '', // 住宿费-金额
    lodgingAmountUnit: '1430000212834011121', // 住宿费-补贴金额单位
    lodgingConditionInfo: '', // 住宿费-扣减说明说明
    lodgingIncludeHydropower: 0,
  },
  'foodEnabled': {
    entryName: 'foodEnabled',
    foodEnabled: 0, //伙食费-是否启用：0-否, 1-是
    foodMode: '1430000212834012148', // 伙食费-伙食费-扣减方式
    foodAmount: '', // 伙食费-金额
    foodAmountUnit: '1430000212834011121', // 伙食费-补贴金额单位
    foodConditionInfo: '', // 伙食费-扣减说明
  },
  'hydropowerEnabled': {
    entryName: 'hydropowerEnabled',
    hydropowerEnabled: 0, //水电费-是否启用：0-否, 1-是
    hydropowerMode: '1430000212834011148', // 水电费-水电费-扣减方式
    hydropowerAmount: '', // 水电费-金额
    hydropowerAmountUnit: '1430000212834011121', // 水电费-单位
    hydropowerConditionInfo: '', // 水电费-扣减说明说明
  },
  'insuranceEnabled': {
    entryName: 'insuranceEnabled',
    insuranceEnabled: 0, //保险费-是否启用：0-否, 1-是
    insuranceAmount: '', // 保险费-金额
    insuranceConditionInfo: '', // 保险费-扣减说明说明
  },
  'breachEnabled': {
    entryName: 'breachEnabled',
    breachEnabled: 0, // 违约金-是否启用：0-否, 1-是
    breachAmount: '', // 违约金-金额
    breachConditionInfo: '', // 违约金-扣减说明说明
  },
  'trainingEnabled': {
    entryName: 'trainingEnabled',
    trainingEnabled: 0, // 培训费-是否启用：0-否, 1-是
    trainingAmount: '', // 培训费-金额
    trainingConditionInfo: '', // 培训费-扣减说明说明
  },
  'otherEnabled': {
    entryName: 'otherEnabled',
    otherEnabled: 0, // 其他-是否启用：0-否, 1-是
    otherAmount: '', // 其他-金额
    otherConditionInfo: '', // 其他-扣减说明说明
  },

}
export const subsidyNameArray = ['foodEnabled', 'fareEnabled', 'healthCheckEnabled', 'awardEnabled', 'otherSubsidyEnabled', 'stayEnabled', 'nightEnabled', 'fareReimbursementEnabled', 'recommendEnabled']
export const deductionNameArray = ['lodgingEnabled', 'foodEnabled', 'hydropowerEnabled', 'insuranceEnabled', 'breachEnabled', 'trainingEnabled', 'otherEnabled']

export const fareReimbursementTypeOptions = [
  { value: 0, label: '固定金额' },
  { value: 1, label: '按里程计费' },
]

export const fareReimbursementOnJobTypeOptions = [
  { value: 0, label: '在职时长' },
  { value: 1, label: '在职到指定日期' },
]

export const recommendGrantConditionOptions = [
  { value: 1, label: '在职时长' },
  { value: 2, label: '在职到指定日期' },
]

export const lodgingIncludeHydropowerOptions = [
  { value: 1, label: '包含' },
  { value: 0, label: '不包含' },
]

export const recruitmentChannelOptions = [
  { value: 0, label: '平台直招' },
  { value: 1, label: '平台外招' }
]

export const limitOptions = [
  { value: 1, label: '限制' },
  { value: 0, label: '不限' },
]
export const regionLimitOptions = [
  {
    "ids": [
      [
        "000000"
      ]
    ],
    "names": [
      [
        "不限"
      ]
    ],
    "mc": [
      "不限"
    ]
  }
]

