import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

// 云门店
interface CounterState {
  msgContent: any;
  firstStepData: any;
  secondStepData: any;
  thirdStepData: any;
}

const initialState: CounterState = {
  msgContent: null,
  firstStepData: null,
  secondStepData: null,
  thirdStepData: null
}

export const userSlice = createSlice({
  name: 'cloudShop',
  initialState,
  reducers: {

    updateOperMsg: (state, action: PayloadAction<{ msgContent: object }>) => {
      const { msgContent } = action.payload;
      state.msgContent = { ...state.msgContent, ...msgContent };
    },
    updateFirstData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.firstStepData = { ...data };
    },
    updateSecondData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.secondStepData = { ...data };
    },
    updateThirdData: (state, action: PayloadAction<{ data: object }>) => {
      const { data } = action.payload;
      state.thirdStepData = { ...data };
    },
    clearOperMsg: (state) => {
      state.msgContent = null;
      state.firstStepData = null;
      state.secondStepData = null;
      state.thirdStepData = null;
    }
  },
})

export const { updateOperMsg, updateFirstData, updateSecondData, updateThirdData, clearOperMsg } = userSlice.actions

export const selectCount = (state: RootState) => state.user.value

export default userSlice.reducer